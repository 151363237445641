import React, { useState, useRef } from "react";
import api from "../../services/api";
import Modal from "../../components/common/Modal";
import { InputField } from "../../components";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

function ImportSeoModal({ setOpen, isExcelImport, fetchBacklinksData }) {
  const [showWarning, setShowWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidate, setIsValidate] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [errors, setErrors] = useState({});
  const [excelFile, setExcelFile] = useState();
  const [domain, setDomain] = useState("");

  const handleModal = () => {
    setOpen(!isExcelImport);
  };
  const cancelButtonRef = useRef(null);

  const handleSubmit = (e, skip_error) => {
    e.preventDefault();
    const errors = {};
    if (!domain.trim()) {
      errors.domain = "Please enter the money domain.";
    }
    if (!excelFile) {
      errors.file = "Please select a file.";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", excelFile);
    formData.append("domain", domain);
    if (skip_error) {
      formData.append("skip_errors", true);
    }
    if (!isValidate) {
      setIsLoading(true);
      setErrorMsg(null);
      const apiUrl = `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/import_seo_backlinks`;
      api
        .post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            toast.success("This file import successfully");
            handleModal();
            fetchBacklinksData({
              page: 1,
              perPage: 100,
              filter: [],
            });
          } else {
            toast.error(
              response.data && typeof response.data === "string"
                ? response.data
                : response.data?.message || "This file couldn't be import."
            );
          }
        })
        .catch((error) => {
          console.log(error);
          setErrorMsg(
            error?.response.data && typeof error?.response.data === "string"
              ? error?.response.data
              : error?.response.data?.message ||
                  "This file couldn't be imported."
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const onDrop = React.useCallback((acceptedFiles) => {
    let files = acceptedFiles[0];
    setExcelFile(files);
    setShowWarning(false);
    setIsValidate(null);
    setErrorMsg(null);
  }, []);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  const saveBlobAsExcelFile = (blobData) => {
    const downloadLink = window.URL.createObjectURL(new Blob([blobData]));

    const link = document.createElement("a");
    link.href = downloadLink;
    link.setAttribute("download", `seo_backlinks.xlsx`);

    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadLink);
  };

  const handleSampleExport = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/export_sample_seo_backlinks_excel`,
        { responseType: "blob" }
      );
      if (res.status === 200) {
        const blobData = res.data;
        saveBlobAsExcelFile(blobData);
        toast.success("Sample export successfully");
      } else {
        toast.error("Sample couldn't be export successfully");
      }
      setIsLoading(false);
    } catch (err) {
      toast.error("Sample couldn't be export successfully");
      setIsLoading(false);
      console.log(err);
    }
  };
  return (
    <>
      <Modal
        open={false}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        handleModalSubmit={handleSubmit}
        className="max-w-3xl"
        title={"Import SEO Backlinks"}
        isLoading={isLoading}
        handleImportCsv={handleSubmit}
        buttonText={"Import"}
      >
        <div className="grid gap-4 dark:text-white">
          <div className="flex flex-col justify-center w-full mt-3">
            <div className="w-[400px]">
              <InputField
                label="Money Domain"
                name="domain"
                placeholder="Enter Money Domain"
                onChange={(e) => setDomain(e.target.value)}
                error={errors.domain}
                required={true}
              />
            </div>
            <div
              className={`!mt-3 w-full flex justify-center border-2 border-dashed rounded-md !p-2 hover:border-yellow-500 focus:outline-none ${
                isDragActive ? "bg-gray-100" : "bg-white dark:bg-dark"
              } ${
                errors?.file
                  ? "border-red-500"
                  : "border-gray-200 dark:border-white/20"
              }`}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <label
                className={`flex justify-center w-full h-[130px] px-4 transition appearance-none cursor-pointer`}
                onClick={open}
              >
                {excelFile ? (
                  <span className="flex items-center space-x-2">
                    <div className="flex flex-col text-center">
                      <span className="font-medium text-gray-600">
                        File Name: {excelFile?.name}
                      </span>
                      <span className="text-gray-600">
                        Size:{" "}
                        {excelFile?.size > 1000 && excelFile.size < 1000000
                          ? (excelFile.size / 1000).toFixed(2) + " KB"
                          : (excelFile.size / 1000000).toFixed(2) + " MB"}
                      </span>
                    </div>
                  </span>
                ) : (
                  <span className="flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      />
                    </svg>
                    <span className="font-medium text-gray-600">
                      Drop files to Attach, or{" "}
                      <span className="!text-yellow-500 underline">browse</span>
                    </span>
                  </span>
                )}
              </label>
            </div>
            {errors.file && <div className="text-red-500">{errors.file}</div>}
            {errorMsg && <div className="text-red-500">{errorMsg}</div>}
            {showWarning && (
              <p className="text-red-500 text-center">Please select a file</p>
            )}
          </div>
        </div>

        <div className="flex items-center justify-end mt-4 space-x-2 text-white">
          {isLoading ? (
            <div className="bg-blue/10 flex items-center justify-center rounded-md py-2 w-48">
              <img src="/img/loading.gif" className="w-7" alt="" />
            </div>
          ) : (
            <button
              type="submit"
              className="btnSecondary"
              onClick={(e) => {
                e.preventDefault();
                handleSampleExport();
              }}
            >
              Download sample
            </button>
          )}
          <button
            type="submit"
            className="btnPrimary bg-green-600"
            onClick={(e) => handleSubmit(e)}
          >
            Import
          </button>
          <button
            type="button"
            className="btnPrimary bg-red-500"
            onClick={handleModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
}

export default ImportSeoModal;
