import React, { useState } from "react";
import dayjs from "dayjs";
import { Status } from "../../../components";
import { IconButton } from "@mui/material";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import { Badge } from "../../../components";
import api from "../../../services/api";
import Loading from "../../../components/Loader/Loading";
const MoneyDomainCard = ({
  taskList,
  handleSelectedBatch,
  is_browser_active,
  is_browser_domain,
  timeConvert,
  toggleTaskStatusDropdown,
  openDropdownIndex,
  updateTask,
  setIsUrlModal,
  setTaskStatus,
  user,
  setErrors,
  selectedPayload,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [showActivityList, setShowActivityList] = useState(null);
  const [activityList, setActivityList] = useState([]);
  const [loadingActivity, setLoadingActivity] = useState(null);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const fetchActivity = (taskId, index) => {
    setIsLoading(true);
    api
      .get(`/api/linkbuilder_user/task_activity/${taskId}`)
      .then((response) => {
        setActivityList(response.data);
        if (response.data?.task_activity === null) {
          toast.error("No Task Activity");
          setShowActivityList(null);
          setLoadingActivity(false);
        } else {
          setShowActivityList((prevIndex) =>
            prevIndex === index ? null : index
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  const renderDetailItem = (label, value, className, link) => {
    if (link) {
      return (
        <p className={className}>
          <b className="text-gray-600 dark:text-white">{label}:</b>
          <a
            href={`${value}`}
            className="hover:text-secondary underline"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <span className="ml-1 text-sm text-gray-500">{value}</span>
          </a>
        </p>
      );
    } else {
      return (
        <p className={`${className} pb-2 flex items-baseline`}>
          <b className="text-gray-600 dark:text-white">{label}:</b>
          <span className="ml-1 text-sm text-gray-500">{value}</span>
        </p>
      );
    }
  };
  function addSpaceBeforeUpperCase(s) {
    return s && s?.replace(/([A-Z])/g, " $1")?.trim();
  }

  const handleInreviewToPending = (e, prevStatus, itemId) => {
    e.preventDefault();
    if (prevStatus === "inReview") {
      setIsUrlModal(true);
      setTaskStatus({
        rowId: itemId,
        status: "pending",
        prevStatus: prevStatus,
      });
    } else {
      updateTask(itemId, "pending", prevStatus);
    }
  };
  return (
    <>
      {isLoading && <Loading />}
      <div
        className={`bg-white dark:bg-gray-800 last:border-none shadow-md shadow-black/20 dark:border-white/20"
      }`}
      >
        <div className="grid md:grid-cols-domainCard items-center px-6 dark:border-white/20 border-b py-0">
          <div className="flex items-center text-sm gap-3 flex-wrap justify-between">
            <IconButton
              sx={{
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
              onClick={handleToggle}
            >
              {isOpen ? (
                <MdKeyboardArrowDown size={22} />
              ) : (
                <MdKeyboardArrowRight size={22} />
              )}
              <a
                href={`https://www.${selectedPayload?.domain_data?.domain}`}
                className="hover:text-secondary underline mx-2 text-base"
                target="_blank"
                rel="noreferrer"
              >
                {selectedPayload?.domain_data?.domain}
              </a>
              <Badge value={taskList?.length} />
            </IconButton>
          </div>

          {isOpen && taskList.length
            ? taskList.map((item, index) => (
                <div className="grid md:grid-cols-domainCard items-center px-6 dark:border-white/20 border-b py-0" key={index}>
                  <div
                    className={`overflow-hidden transition-max-height duration-500 ease-in-out px-6  max-h-screen py-3 overflow-x-auto" : "max-h-0`}
                  >
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 text-sm">
                      <div>
                        <h2 className="text-base  font-medium mb-1">
                          Money Site Info
                        </h2>
                        {renderDetailItem("City", item?.city)}
                        {renderDetailItem("Phone", item?.phone)}
                        {renderDetailItem("Domain", selectedPayload?.domain_data?.domain)}
                      </div>
                      <div>
                        <h2 className="text-base font-medium mb-1">
                          Backlink URL Info
                        </h2>
                        {renderDetailItem(
                          "Live Status",
                          addSpaceBeforeUpperCase(item?.live_status)
                        )}
                        {renderDetailItem(
                          "Backlink Type",
                          addSpaceBeforeUpperCase(item?.backlink_type)
                        )}
                        {renderDetailItem(
                          "Backlink Domain",
                          addSpaceBeforeUpperCase(item?.backlink_name)
                        )}
                        {renderDetailItem(
                          "Backlink Live URL",
                          item?.link_to_website,
                          "",
                          "link"
                        )}
                      </div>
                      <div>
                        <h2 className="text-base  font-medium mb-1">
                          Task Details
                        </h2>
                        {renderDetailItem(
                          "Assigned Date",
                          dayjs(item.assigned_date)?.format("ddd, MMM D, YYYY")
                        )}
                        {renderDetailItem("Assigned To", item?.name)}
                        {renderDetailItem("Batch Number", item?.batch_number)}
                        <div>
                          <div className="flex items-center gap-1 mt-0">
                            <span className="font-bold text-gray-500 dark:text-white">
                              Activity:
                            </span>
                            <div className="flex flex-col items-center relative">
                              <button className="flex items-center gap-2">
                                Show Activity
                                {loadingActivity === item.id ? (
                                  <img
                                    src="/img/loading.gif"
                                    className="w-4"
                                    alt=""
                                  />
                                ) : (
                                  <>
                                    {showActivityList === item.id ? (
                                      <XMarkIcon
                                        onClick={() =>
                                          setShowActivityList(null)
                                        }
                                        className="w-4 text-red-400"
                                      />
                                    ) : (
                                      <ChevronDownIcon
                                        onClick={() =>
                                          fetchActivity(item.id, item.id)
                                        }
                                        className="w-4 text-gray-500"
                                      />
                                    )}
                                  </>
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="relative flex justify-between">
                            {showActivityList === item.id && (
                              <>
                                <div className="bg-white dark:bg-white/20 backdrop-blur-lg text-sm shadow-xl dark:shadow-black mt-1 p-3 rounded-md flex flex-col w-80  min-h-[100px] max-h-[150px] overflow-y-scroll border dark:border-white/20 ">
                                  {activityList?.task_activity?.map(
                                    (item, index) => (
                                      <div
                                        key={index}
                                        className="flex items-center justify-between border-b hover:bg-gray-100 dark:hover:bg-white/10 hover:rounded p-2"
                                      >
                                        <div className="capitalize">
                                          {" "}
                                          {addSpaceBeforeUpperCase(item.status)}
                                        </div>
                                        <div>{timeConvert(item.timestamp)}</div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="grid grid-cols-3 gap-0">
                          <div></div>
                          <div className="col-span-2 mb-3 ml-0">
                            <button
                              className="btnPrimary py-2 px-2 text-xs rounded-full capitalize text-[#11223c] font-semibold w-[139px]"
                              onClick={() => {
                                // if (
                                //   +is_browser_active &&
                                //   +is_browser_active === 1 &&
                                //   is_browser_domain !== item.domain
                                // )
                                //   return toast.error(
                                //     "Please stop the previous browser"
                                //   );
                                handleSelectedBatch(item);
                              }}
                            >
                              Start Task
                            </button>
                          </div>
                        </div>
                        <div className="grid grid-cols-3 gap-0">
                          <span className="font-bold text-gray-600 dark:text-white mt-2">
                            Task Status:
                          </span>
                          <Status type={item.task_status} />
                          <div className="flex items-center gap-2">
                            <button
                              onClick={() => toggleTaskStatusDropdown(item.id)}
                              className="absolute"
                            >
                              {openDropdownIndex === item.id ? (
                                <XMarkIcon className="w-4 text-red-400" />
                              ) : (
                                <ChevronDownIcon className="w-4 text-gray-500" />
                              )}
                              <div className="relative flex justify-end">
                                {openDropdownIndex === item.id && (
                                  <div className="bg-white dark:bg-dark dark:shadow-black shadow-md absolute top-full left-1/2 transform -translate-x-1/2 text-left p-2 mt-1 rounded-md flex flex-col w-32 z-50">
                                    <button
                                      onClick={(e) =>
                                        handleInreviewToPending(
                                          e,
                                          item.task_status,
                                          item.id
                                        )
                                      }
                                      className={`py-2 px-4 hover:bg-gray-50 text-red-600 dark:text-red-400 rounded ${
                                        item.task_status === "pending" &&
                                        "bg-red-100"
                                      }`}
                                    >
                                      Pending
                                    </button>
                                    <button
                                      onClick={() => {
                                        updateTask(item.id, "inProcess");
                                      }}
                                      className={`py-2 px-4 hover:bg-gray-50 text-indigo-600 dark:text-indigo-300 rounded ${
                                        item.task_status === "inProcess" &&
                                        "bg-indigo-100"
                                      }`}
                                    >
                                      In Process
                                    </button>
                                    <button
                                      onClick={() => {
                                        updateTask(item.id, "inReview");
                                      }}
                                      className={`py-2 px-4 hover:bg-gray-50 text-indigo-600 dark:text-indigo-300 rounded ${
                                        item.task_status === "inReview" &&
                                        "bg-indigo-100"
                                      }`}
                                    >
                                      In Review
                                    </button>
                                    <button
                                      onClick={() => {
                                        setIsUrlModal(true);
                                        setErrors({});
                                        setTaskStatus({
                                          rowId: item.id,
                                          status: "hold",
                                        });
                                      }}
                                      className={`py-2 px-4 hover:bg-gray-50 text-gray-600 dark:text-gray-300 rounded ${
                                        item.task_status === "hold" &&
                                        "bg-gray-100"
                                      }`}
                                    >
                                      Hold
                                    </button>
                                    <button
                                      onClick={() => {
                                        setIsUrlModal(true);
                                        setErrors({});
                                        setTaskStatus({
                                          rowId: item.id,
                                          status: "cancelled",
                                        });
                                      }}
                                      className={`py-2 px-4 hover:bg-gray-50 text-red-600 dark:text-gray-300 rounded ${
                                        item.task_status === "cancelled" &&
                                        "bg-gray-100"
                                      }`}
                                    >
                                      Cancelled
                                    </button>
                                    <button
                                      onClick={() => {
                                        updateTask(item.id, "completed");
                                      }}
                                      className={`py-2 px-4 hover:bg-gray-50 text-green-600 dark:text-green-300 rounded ${
                                        item.task_status === "completed" &&
                                        "bg-gray-50"
                                      }`}
                                    >
                                      Completed
                                    </button>
                                    <button
                                      onClick={() => {
                                        setIsUrlModal(true);
                                        setErrors({});
                                        setTaskStatus({
                                          rowId: item.id,
                                          status: "reOpen",
                                        });
                                      }}
                                      className={`py-2 px-4 hover:bg-gray-50 text-indigo-600 dark:text-indigo-300 rounded ${
                                        item.task_status === "reOpen" &&
                                        "bg-gray-100"
                                      }`}
                                    >
                                      Reopen
                                    </button>
                                  </div>
                                )}
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
        {taskList && taskList.length === 0 ? (
          <div className="grid md:grid-cols-domainCard items-center px-6 dark:border-white/20 border-t py-0 bg-gray-100">
            <div className="flex justify-center px-3 py-3 font-normal text-sm">
              No Records
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default MoneyDomainCard;
