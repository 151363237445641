import React, { useEffect, useRef, useState } from "react";
import { FaSearch, FaLink } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import Loading from "../../components/Loader/Loading";
import MUIDataTable from "../../components/MUIGrid";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton, TablePagination } from "@mui/material";
import Loader from "../../components/Loader/Loading";
const DomainsReport = ({ handleDomainData, selectedData, setSelectedData }) => {
  const [domainReport, setDomainReport] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });

  const getDomainList = async (filter) => {
    setSelectedData([]);
    setIsLoading(true);
    let payload = { ...filter };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const res = await api.post("/api/domains", payload);
      if (res.status === 200) {
        setDomainReport(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getDomainList({
      ...paginationModel,
      perPage: paginationModel.pageSize,
      filters: queryOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getDomainList({
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getDomainList({
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getDomainList({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };
  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    getDomainList({
      filters: queryOptions,
      page: +page + 1,
      perPage: paginationModel.pageSize,
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    getDomainList({
      filters: queryOptions,
      page: 1,
      perPage: newPageSize,
    });
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="flex items-center space-x-0 my-2 justify-between">
        <div className="flex">
          {/* <GridToolbarColumnsButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarDensitySelector
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          /> */}
          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            ref={setFilterButtonEl}
          />
          <MUIButton
            variant="text"
            onClick={handleNewFilter}
            startIcon={<FaSearch size={16} />}
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            Apply filter
          </MUIButton>
        </div>
        <div className="flex items-center">
          <TablePagination
            color="primary"
            shape="rounded"
            size="medium"
            showFirstButton
            showLastButton
            count={
              domainReport?.count
                ? domainReport?.count
                : domainReport?.domainListData?.length || 0
            }
            page={paginationModel.page - 1 || 0}
            onPageChange={(e, page) => handleTopPageChange(page)}
            rowsPerPage={paginationModel.pageSize || 100}
            onRowsPerPageChange={(e) => handleTopPageSizeChange(e.target.value)}
            component="div"
            rowsPerPageOptions={[
              25,
              50,
              75,
              100,
              250,
              500,
              1000,
              1500,
              2000,
              {
                label: "All",
                value: domainReport?.count
                  ? domainReport?.count
                  : domainReport?.domainListData?.length || 0,
              },
            ]}
          />
        </div>
      </GridToolbarContainer>
    );
  }
  const checkboxColumn = [
    {
      field: "",
      column: "",
      /*   renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={
            selectedData?.length === domainReport?.domainListData?.length
          }
        />
      ), */
      filterable: false,
      sortable: false,
      width: 10,
      renderCell: (params) => (
        <div className="flex justify-center w-full items-center">
          {isSelected(params.row.records) && isLoading ? (
            <Loader />
          ) : (
            <input
              type="checkbox"
              checked={isSelected(params.row.records)}
              onChange={(e) =>
                onSingleSelect({
                  checked: e.target.checked,
                  data: params.row.records,
                })
              }
              className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
            />
          )}
        </div>
      ),
      disableColumnMenu: true,
      hideable: false,
    },
  ];
  const columnDefs = [
    ...checkboxColumn,
    { headerName: "#", field: "counter", width: 10, filterable: false },
    {
      headerName: "Domain",
      field: "d.domain",
      width: 220,
      renderCell: (params) => {
        return (
          <a
            href={`https://www.${params.row["d.domain"]}`}
            className="hover:text-secondary underline"
            target="_blank"
            rel="noreferrer"
          >
            {params.row["d.domain"]}
          </a>
        );
      },
    },
    {
      headerName: "Backlinks",
      field: "backlinks_count",
      flex: 1,
      minWidth: 100,
      renderCell: (params) =>
        params?.row.backlinks_count > 0 ? (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            {params?.row.backlinks_count}
          </span>
        ) : (
          <span className="py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      headerName: "First Name",
      field: "d.fname",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Last Name",
      field: "d.lname",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Email",
      field: "d.email",
      flex: 1,
      minWidth: 100,
    },
  ];

  const dataRows = (domainReport?.domainListData || [])?.map(
    (record, index) => {
      const counter = index + 1;
      const records = record;
      const {
        "d.id": id,
        "d.domain": backlink_domain,
        backlinks_count,
        "d.fname": fname,
        "d.lname": lname,
        "d.email": email,
        "d.phone": phone,
        "i.industry_name": industry_name,
        "d.state": state,
        "d.city": city,
        "d.created_at": created_at,
      } = record;

      return {
        counter,
        records,
        "b.id": id,
        "d.domain": backlink_domain,
        backlinks_count,
        "d.fname": fname || "",
        "d.lname": lname,
        "d.email": email,
        "d.phone": phone,
        "i.industry_name": industry_name || "",
        "d.state": state || "",
        "d.city": city,
        "d.created_at": created_at,
      };
    }
  );

  const onSelectAll = (checked) => {
    if (isLoading) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (checked) {
      const updatedArray = domainReport?.domainListData?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };

  const isSelected = (item) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter(
          ({ "d.domain": backlinkDomain }) =>
            backlinkDomain && backlinkDomain === item["d.domain"]
        ).length > 0
      ) {
        return true;
      }
    }
    return false;
  };

  const onSingleSelect = ({ checked, data }) => {
    if (isLoading) {
      return toast.error("Please wait until the previous process is complete.");
    }
    try {
      if (checked) {
        if (selectedData?.length > 0) {
          return toast.error("You can only select one record at a time.");
        }

        setSelectedData([data]);
      } else {
        setSelectedData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleDomainData(selectedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  return (
    <div className="px-4 pt-0">
      <div className="font-semibold text-2xl">Domains</div>
      <ToastContainer />
      {isLoading && <Loading />}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={dataRows}
          totalItems={domainReport?.count}
          paginationModel={paginationModel}
          CustomToolbar={CustomToolbar}
          height="88vh"
          searchable="No"
          showCount="No"
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
        />
      </div>
    </div>
  );
};

export default DomainsReport;
