import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useSelector } from "react-redux";

export default function Dropdown(props) {
  const {
    label,
    options,
    Icon,
    show_navlabel,
    activeMenuItem,
    setActiveMenuItem,
  } = props;

  const location = useLocation();
  const { user } = useSelector((state) => state.auth);

  const isAllow = (item) => {
    if (user?.permissions?.some((obj) => obj === item?.label)) {
      return true;
    } else {
      return false;
    }
  };
  const isParentActive = (menuItems) => {
    return Object.keys(menuItems).some((key) => {
      const menuItem = menuItems[key];
      return location.pathname === menuItem.to;
    });
  };

  const renderDropdownOptions = (menuItems) => {
    return Object.keys(menuItems).map((key, index) => {
      const menuItem = menuItems[key];
      const isActive = activeMenuItem === menuItem.label;
      if (menuItem.children) {
        if (!isAllow(menuItem)) {
          return null;
        }
        return (
          <Menu.Item key={index}>
            {({ active }) => (
              <div>
                <Menu.Items
                  static
                  className="dropdown_submenu bg-white dark:bg-blue dark:shadow-black/30 shadow-lg p-3 rounded-xl text-black"
                >
                  {renderDropdownOptions(menuItem.children)}
                </Menu.Items>
              </div>
            )}
          </Menu.Item>
        );
      }
      if (!isAllow(menuItem)) {
        return null;
      }
      return menuItem?.action ? (
        <Menu.Item key={index}>
          {({ active }) => (
            <button
              className={`pageLink py-1 dark:text-white dark:hover:text-white hover:bg-blue/10 ${
                show_navlabel && "dark:hover:text-white hover:bg-blue/10"
              } ${isActive ? "bg-secondary" : ""}`}
              title={menuItem.label}
              onClick={menuItem.action}
            >
              {menuItem.Icon && <menuItem.Icon />}
              <span className="ml-3 capitalize">{menuItem.label}</span>
            </button>
          )}
        </Menu.Item>
      ) : (
        <Menu.Item key={index}>
          {({ active }) => (
            <Link
              className={`pageLink py-1 dark:text-white dark:hover:text-white hover:bg-blue/10 ${
                show_navlabel && "dark:hover:text-white hover:bg-blue/10"
              } ${isActive ? "bg-secondary" : ""}`}
              title={menuItem.label}
              to={`${menuItem.to}`}
              onClick={() => {
                if    (menuItem.to === "/settings/niche-sub-category")    {
                  localStorage.removeItem("niche_category");
                }
                setActiveMenuItem && setActiveMenuItem(menuItem.label);
              }}
            >
              {menuItem.Icon && <menuItem.Icon />}
              <span className="ml-3 capitalize">{menuItem.label}</span>
            </Link>
          )}
        </Menu.Item>
      );
    });
  };

  if (!isAllow({ label: label })) {
    return null;
  }

  return (
    <Menu
      as="div"
      className={`drop_down_menu ${
        isParentActive(options)
          ? "bg-secondary text-white hover:bg-secondary px-2 rounded-md"
          : ""
      }`}
    >
      <div>
        <Menu.Button
          title={label}
          className={` pageLink justify-between ${
            isParentActive(options)
              ? "bg-highlight hover:bg-secondary"
              : "pageLink hover:bg-blue/10 dark:hover:bg-white/10"
          }`}
        >
          <div
            className={
              show_navlabel
                ? "flex items-center w-full "
                : "flex items-center w-full justify-center"
            }
          >
            {Icon && <Icon />}
            <span
              className={show_navlabel ? "ml-3 capitalize" : "ml-1 capitalize "}
            >
              {label}
            </span>
          </div>
          <ChevronDownIcon className="w-4" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={
            show_navlabel
              ? "dropdown_options bg-beta rounded-xl pl-5"
              : "dropdown_options_small bg-white dark:bg-blue dark:shadow-black/30 shadow-lg p-3 rounded-xl absolute top-0 mt-12 text-black"
          }
        >
          {renderDropdownOptions(options)}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
