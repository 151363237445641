import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getNicheCategory } from "../../services/nicheCategoryService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Niche Category
export const get_niche_category = createAsyncThunk(
  "get_niche_category",
  async (thunkAPI) => {
    try {
      return await getNicheCategory();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const nicheCategorySlice = createSlice({
  name: "nicheCategory",
  initialState,
  reducers: {
    apiUserReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_niche_category.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_niche_category.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_niche_category.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { nicheCategoryReset } = nicheCategorySlice.actions;
export default nicheCategorySlice.reducer;
