import React from "react";
import { FormControl } from "@mui/material";
import AutoComplete from "./AutoComplete";
const StartBrowser = ({
  data,
  idField,
  handleModal,
  handleSubmit,
  isLoading,
  domain,
  setDomain,
  isLocation,
  handleSelect,
  handleQueryChange,
  setAutoComLoader,
  isAutoComLoader,
  selectedPayload,
  setLocationdata,
}) => {
  return (
    <div className="relative">
      <div className="grid md:grid-cols-1">
        <FormControl fullWidth>
          <AutoComplete
            data={isLocation}
            idField="id"
            valueField="name"
            onSelect={handleSelect}
            onQueryChange={handleQueryChange}
            placeholder="Search City ..."
            setAutoComLoader={setAutoComLoader}
            isAutoComLoader={isAutoComLoader}
            selectedItem={selectedPayload}
            extraClass="mt-1"
            setLocationdata={setLocationdata}
            dropPosition={"inherit"}
          />
        </FormControl>
      </div>
      <div className="flex items-cente justify-end mt-5 space-x-2 text-white">
        <button
          onClick={handleModal}
          type="button"
          className="btnPrimary bg-red-500"
        >
          Cancel
        </button>

        {isLoading ? (
          <div className="bg-blue/10 flex items-center justify-center rounded-md py-2 w-48">
            <img src="/img/loading.gif" className="w-7" alt="" />
          </div>
        ) : (
          <button
            type="button"
            onClick={handleSubmit}
            className="btnPrimary bg-green-600"
          >
            Start
          </button>
        )}
      </div>
    </div>
  );
};
export default StartBrowser;
