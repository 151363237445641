import React, { useState, useEffect, useCallback, useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal, TextArea } from "../../../components";
import CustomPagination from "../../../components/common/CustomPagination";
import api from "../../../services/api";
import axios from "axios";
import { useDispatch } from "react-redux";
import { BsBrowserChrome } from "react-icons/bs";
import AutoComplete from "../../../components/common/AutoComplete";
import {
  setIsBrowserActive,
  setIsBrowserDomain,
  setLoggedInUser,
} from "../../../store/slices/authSlice";
import debounce from "lodash/debounce";
const TaskListHeader = ({
  LinkBuilders,
  setSelectedPayload,
  selectedPayload,
  data,
  targetId,
  setTasksList,
  fetchTasksList,
  is_browser_active,
  is_browser_domain,
  setExpandedRows,
  setFilters,
  user,
  setDefault,
  selectedData,
  fetchFilterData,
  paginationModel,
  queryParams,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const totalPages = data?.length;
  const [isBacklinkDomains, setBacklinksDomains] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAutoComLoader, setAutoComLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    selectedPayload?.backlink_data || ""
  );
  const [statusPayload, setStatusPayload] = useState({
    task_ids: selectedData,
    task_status: "",
    note: "",
  });

  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleTaskModal = () => {
    setOpen(!open);
  };
  // Determine the initial page index based on targetId
  const initialIndex = targetId
    ? data.findIndex((record) => record["bl.id"] === targetId)
    : -1;
  const [currentPageIndex, setCurrentPageIndex] = useState(
    initialIndex !== -1 ? initialIndex : 0
  );

  useEffect(() => {
    if (initialIndex === -1) {
      setCurrentPageIndex(0);
    } else {
      setCurrentPageIndex(initialIndex);
    }
  }, [initialIndex, targetId]);

  useEffect(() => {
    const currentRecord = data[currentPageIndex];
    setSelectedPayload((prevState) => ({
      ...prevState,
      open_backlinkid: currentRecord ? currentRecord["bl.id"] : "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageIndex, data]);

  const handlePageChange = async (newPageIndex) => {
    setStatusPayload((prevState) => ({
      ...prevState,
      task_ids: [],
      task_status: "",
      note: "",
    }));
    setExpandedRows({});
    setTasksList((prev) =>
      prev.map((item) => ({
        ...item,
        childRows: [],
      }))
    );
    await setSelectedPayload((prevState) => ({
      ...prevState,
      prevPageId: "",
      pageSize: paginationModel?.pageSize,
      page: paginationModel?.page,
    }));
    if (newPageIndex < 1 || newPageIndex > totalPages) {
      toast.error(`Please enter a valid page number (1-${totalPages})`);
      return;
    }
    setCurrentPageIndex(newPageIndex - 1);
  };

  const taskStatusOptions = [
    { value: "hold", label: "Hold" },
    { value: "inReview", label: "In Review" },
    { value: "completed", label: "Completed" },
    { value: "cancelled", label: "Cancelled" },
  ];
  const allTaskStatusOptions = [
    { value: "pending", label: "Pending" },
    { value: "inProcess", label: "In Process" },
    { value: "hold", label: "Hold" },
    { value: "cancelled", label: "Cancelled" },
    { value: "completed", label: "Completed" },
    { value: "inReview", label: "In Review" },
    { value: "reOpen", label: "Reopen" },
  ];
  const handleUserChange = (e) => {
    const newUserId = e.target.value;
    setSelectedPayload((prevState) => ({
      ...prevState,
      user_id: newUserId || "",
    }));
  };

  const handleTaskStatusChange = (e) => {
    const newTaskStatus = e.target.value;
    setSelectedPayload((prevState) => ({
      ...prevState,
      task_status: newTaskStatus || "",
    }));
  };
  useEffect(() => {
    // Perform side effect when selectedPayload changes
  }, [selectedPayload]);

  useEffect(() => {
    setStatusPayload((prevState) => ({
      ...prevState,
      task_ids: selectedData,
    }));
  }, [selectedData]);

  const handleResetFilters = async () => {
    setExpandedRows({});
    setStatusPayload((prevState) => ({
      ...prevState,
      task_ids: [],
      task_status: "",
      note: "",
      updated_at: "",
    }));
    setTasksList([]);
    setDefault(false);
    setBacklinksDomains([]);
    setSelectedItem("");
    setCurrentPageIndex(0);
    const newFilters = {
      groupOp: "AND",
      rules: [],
    };
    fetchTasksList({
      filter: newFilters,
    });

    setSelectedPayload((prevState) => ({
      ...prevState,
      task_status: "",
      user_id: "",
      open_backlinkid: "",
      prevPageId: "",
      backlink_data: {},
      updated_at: "",
      pageSize: paginationModel?.pageSize,
      page: paginationModel?.page,
    }));
    //fetchTasksWithOpenStatus({});
    navigate(`/reports/review-task/`, {
      state: {
        selectedPayload: {
          task_status: "",
          user_id: "",
          open_backlinkid: "",
          updated_at: "",
        },
      },
    });
    //window.location.reload();
  };
  const handleStopBrowser = async () => {
    setIsLoading(true);
    try {
      const launch = await api.post("/api/multilogin/stop_browser_static_ip");
      if (launch.status === 200) {
        const urlLaunch = await axios.get(launch.data.stop_url, {
          headers: { Authorization: `Bearer ${launch.data.token}` },
        });
        if (urlLaunch.data?.status?.http_code === 200) {
          const update = await api.post(
            "/api/multilogin/update_browser_status_static_ip",
            {
              is_browser_active: 0,
            }
          );
          if (update.status === 200) {
            toast.success("Browser stopped successfully");
            dispatch(setIsBrowserActive(null));
            dispatch(setIsBrowserDomain(null));
          } else {
            toast.error(
              update?.data?.message || "Browser status couldn't be updated"
            );
          }
        } else {
          toast.error(
            urlLaunch?.data?.message || "Browser couldn't be stopped"
          );
        }
      } else {
        toast.error(launch?.data?.message || "Browser couldn't be stopped");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.status?.message === "profile already stopped"
      ) {
        toast.success("Browser stopped successfully");
        dispatch(setIsBrowserActive(null));
        dispatch(setIsBrowserDomain(null));
        return await api.post(
          "/api/multilogin/update_browser_status_static_ip",
          {
            is_browser_active: 0,
          }
        );
      }
      toast.error(
        error?.response?.data?.message || "Browser couldn't be stopped"
      );
      if (error?.response?.data?.message === "Browser not exists") {
        handleLogout();
      }
      console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
    }
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await api.get(`${process.env.REACT_APP_PUBLIC_API}/api/logout`);
      localStorage.clear();
      dispatch(setLoggedInUser(null));
    } catch (err) {
      console.log("👊 ~ handleLogout ~ err:", err);
    }
    setIsLoading(false);
  };

  const handleSearch = async () => {
    setExpandedRows({});
    await setSelectedPayload((prevState) => ({
      ...prevState,
      prevPageId: "",
      pageSize: paginationModel?.pageSize,
      page: paginationModel?.page,
    }));
    setStatusPayload((prevState) => ({
      ...prevState,
      task_ids: [],
      task_status: "",
      note: "",
      updated_at: "",
    }));
    setCurrentPageIndex(0);
    let payload;
    if (
      selectedPayload?.task_status ||
      selectedPayload?.user_id ||
      selectedPayload?.backlink_data?.backlink_id ||
      selectedPayload?.updated_at
    ) {
      payload = {
        groupOp: "AND",
        rules: [],
      };
    }
    if (selectedPayload?.task_status) {
      payload.rules.push({
        field: "bdb.task_status",
        op: "eq",
        data: selectedPayload?.task_status,
      });
    }
    if (selectedPayload?.user_id) {
      payload.rules.push({
        field: "u.id",
        op: "eq",
        data: selectedPayload?.user_id,
      });
    }
    if (selectedPayload?.backlink_data?.backlink_id) {
      payload.rules.push({
        field: "bl.id",
        op: "eq",
        data: selectedPayload?.backlink_data?._id,
      });
    }
    if (selectedPayload?.updated_at) {
      payload.rules.push({
        field: "bdb.updated_at",
        op: "eq",
        data: selectedPayload?.updated_at,
      });
    }
    setFilters(payload);
    fetchTasksList({
      filter: payload,
    });
    /*  fetchTasksWithOpenStatus({
      filter: filters,
    }); */
  };

  const handleSelect = (item) => {
    setSelectedPayload((prevState) => ({
      ...prevState,
      backlink_data: {
        backlink_id: item?.backlink_id,
        _id: item?._id,
      },
    }));
    setSelectedItem(item);
  };
  const fetchData = useCallback(async (searchQuery) => {
    if (searchQuery?.length === 0) {
      setBacklinksDomains([]);
      return;
    }
    const payload = {
      user_id: user?.user?.id,
      is_assigned: true,
      review_task_page: true,
      filter: {
        groupOp: "AND",
        rules: [
          {
            field: "b.backlink_domain",
            op: "cn",
            data: searchQuery,
          },
        ],
      },
    };
    setAutoComLoader(true);
    try {
      const res = await api.post("/api/backlinks/", payload);
      if (res.status === 200 || res.status === 201) {
        setBacklinksDomains(
          res?.data?.backlinksData?.map((item) => ({
            _id: item["b.id"],
            backlink_id: item["b.backlink_domain"],
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
    setAutoComLoader(false);
  }, []);
  const handleQueryChange = useCallback(
    debounce((newQuery) => fetchData(newQuery), 500),
    []
  );

  const handleUpdateTask = () => {
    handleTaskModal();
  };

  const updateTaskStatus = (e) => {
    setIsLoading(true);
    e.preventDefault();
    api
      .post(
        `${process.env.REACT_APP_PUBLIC_API}/api/linkbuilder_user/update_multiple_tasks_status`,
        {
          task_status: statusPayload?.task_status,
          task_ids: statusPayload?.task_ids,
          note: statusPayload?.note,
        }
      )
      .then(() => {
        toast.success("Status Updated", { autoClose: 3000 });
        handleTaskModal();
        fetchFilterData();
        setStatusPayload((prevState) => ({
          ...prevState,
          task_status: "",
          note: "",
        }));
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {!queryParams.get("task_status") && !queryParams.get("domain") ? (
        <div className="bg-white my-2 border rounded">
          <div className="flex flex-wrap items-center px-2 py-2">
            <select
              disabled={isLoading}
              className="inputField disabled:cursor-not-allowed w-fit py-2"
              value={selectedPayload?.user_id}
              onChange={handleUserChange}
            >
              <option key="" value="">
                Select User
              </option>
              {LinkBuilders.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
            <select
              disabled={isLoading}
              className="inputField disabled:cursor-not-allowed w-fit py-2 ml-2"
              value={selectedPayload?.task_status}
              onChange={handleTaskStatusChange}
            >
              <option value="">Select Status</option>
              {taskStatusOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <div className="flex mb-2 md:mb-0 ml-2">
              <AutoComplete
                data={isBacklinkDomains}
                idField="_id"
                valueField="backlink_id"
                onSelect={handleSelect}
                onQueryChange={handleQueryChange}
                placeholder="Search backlink..."
                setAutoComLoader={setAutoComLoader}
                isAutoComLoader={isAutoComLoader}
                selectedItem={selectedItem}
                width="w-[250px]"
                height="h-[37px]"
                extraClass="mr-2"
              />
            </div>
            <input
              className="inputField disabled:cursor-not-allowed w-fit py-2 ml-2 h-[38px]"
              placeholder="Date"
              onChange={(e) => {
                setSelectedPayload((prevState) => ({
                  ...prevState,
                  updated_at: e.target.value || "",
                }));
              }}
              name="DATE(bdb.updated_at)"
              value={selectedPayload?.updated_at}
              type="date"
            />
            <button className="btnPrimary ml-2" onClick={handleSearch}>
              Search
            </button>
            <button
              onClick={handleResetFilters}
              className="btnPrimary ml-1 bg-gray-500"
            >
              Reset
            </button>
            <button
              className="btnPrimary ml-2"
              onClick={handleUpdateTask}
              disabled={!selectedData?.length}
            >
              Update Task
            </button>
            <div className="ml-2">
              {isLoading ? (
                <div className="bg-blue-100 flex items-center justify-start rounded-md py-2 w-full sm:w-auto">
                  <img
                    src="/img/loading.gif"
                    className="w-6 mr-2"
                    alt="Loading"
                  />
                  <span className="text-md text-red-500">
                    Please do not reload the page
                  </span>
                </div>
              ) : +is_browser_active && is_browser_domain ? (
                <button
                  onClick={handleStopBrowser}
                  className="capitalize cursor-pointer btnPrimary py-2 px-2 text-xs font-semibold w-[139px] text-red-600 flex items-center bg-white border rounded-md"
                >
                  <BsBrowserChrome className="mr-1" size={18} />
                  stop browser
                </button>
              ) : null}
            </div>
            <div className="ml-auto mt-2 md:mt-0">
              {data?.length ? (
                <CustomPagination
                  currentPage={currentPageIndex + 1}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : null}

      <Modal
        open={open}
        handleModal={handleTaskModal}
        cancelButtonRef={cancelButtonRef}
        className="max-w-xl"
        title="Update Status"
        handleModalSubmit={updateTaskStatus}
        modalType="new"
      >
        <div className="grid md:grid-cols-1 gap-4 w-full mt-7 ">
          <div>
            <label htmlFor="backlink_status" className="inputLabel">
              Task Status
            </label>
            <select
              value={statusPayload?.task_status || ""}
              onChange={(e) => {
                setStatusPayload((prevState) => ({
                  ...prevState,
                  task_status: e.target.value,
                }));
              }}
              name="task_status"
              id="task_status"
              className="inputField mt-1"
            >
              <option value="">Select Status</option>
              {allTaskStatusOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <TextArea
              label="Note"
              name="note"
              placeholder="Enter note"
              type="text"
              value={statusPayload?.note || ""}
              onChange={(e) => {
                setStatusPayload((prevState) => ({
                  ...prevState,
                  note: e.target.value,
                }));
              }}
              rows={3}
              className="col-span-2"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TaskListHeader;
