import React, { useEffect, useState, useCallback } from "react";
import api from "../../../services/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../components/Loader/Loading";
import { useSelector } from "react-redux";
import URLModal from "../../../pages/TaskList/TaskDetail/URLModal";
import TaskListCard from "../ReviewTask/TaskListCard";
import TaskListHeader from "../ReviewTask/TaskListHeader";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
dayjs.extend(utc);
dayjs.extend(timezone);

export default function TaskList() {
  const { user } = useSelector((state) => state.auth);
  const { is_browser_active, is_browser_domain } = useSelector(
    (state) => state.auth
  );
  const [tasks, setTasksList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [isDefault, setDefault] = useState(false);
  const [isUrlModal, setIsUrlModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [filters, setFilters] = useState({
    groupOp: "",
    rules: [],
  });
  const [link_to_website, setLinkToWebsite] = useState("");
  const [isTaskStatus, setTaskStatus] = useState({});
  const [isStatusNote, setStatusNote] = useState("");
  const [expandedRows, setExpandedRows] = useState({});
  const [taskIds, setTaskIds] = useState([]);
  const [errors, setErrors] = useState({});
  const [totalChildTasks, setTotalChildTasks] = useState([]);
  const [backlinksStatusList, setBacklinksStatusList] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 1,
  });
  const timeConvert = (timestamp) => {
    const timezoneName = dayjs.tz.guess();
    dayjs.tz.setDefault(timezoneName);
    const parsedTime = dayjs(timestamp, "YYYY-MM-DD HH:mm:ss");
    const newTime = parsedTime.add(5, "hour").add(30, "minute");
    const currentTime = dayjs(newTime).format("ddd, MMM D, YYYY h:mm A");
    return currentTime;
  };

  const queryParams = new URLSearchParams(location.search);
  // Linkbuilders Users List
  const [LinkBuilders, setLinkBuilders] = useState([]);
  const initalData = {
    task_status:
      location?.state?.task_status || queryParams.get("task_status") || "",
    updated_at: location?.state?.updated_at || "",
    user_id: location?.state?.user_id || "",
    open_backlinkid: "",
    prevPageId: location?.state?.prevPageId || "",
    backlink_data:
      location?.state?.backlink_data || {
        _id: queryParams.get("backlink_id"),
        backlink_id: queryParams.get("domain"),
      } ||
      {},
    pageSize: location?.state?.pageSize || paginationModel?.pageSize,
    page: location?.state?.page || paginationModel?.page,
  };
 
  const getBacklinkStatusList = () => {
    api
      .get(`/api/backlinks/backlink_status_list`)
      .then((res) => setBacklinksStatusList(res.data))
      .catch((err) => console.log(err));
  };
  const [selectedPayload, setSelectedPayload] = useState(initalData);
  useEffect(() => {
    api
      .post(`/api/users/users_report`)
      .then((res) => {
        if (res.status === 200) {
          setLinkBuilders(
            res.data?.records.filter(
              (item) => item.role_id === 3 || item.role_id === 8
            )
          );
        }
      })
      .catch((err) => console.log(err.data.message))
      .finally(() => setIsLoading(false));
  }, []);

  // Task Status Dropdown
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const toggleTaskStatusDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const updateTask = async (taskId, task_status, prevStatus = null) => {
    setIsLoading(true);
    const taskErrors = {};
    if (prevStatus === "inReview" && task_status === "pending") {
      if (isStatusNote.trim() === "") {
        taskErrors.note_status = "Please enter a note.";
      } else {
        setErrors(taskErrors);
      }
      if (Object.keys(taskErrors).length > 0) {
        await setErrors(taskErrors);
        setIsLoading(false);
        return;
      }
    }
    const requestData = {
      task_status,
      ...(link_to_website && { link_to_website }),
      ...(isStatusNote && { note: isStatusNote }),
      task_ids: [Number(taskId.split(/[^0-9]+/)[0])],
    };
    try {
      const res = await api.post(
        `/api/linkbuilder_user/update_multiple_tasks_status`,
        requestData
      );
      if (res.status === 200) {
        if (isUrlModal) {
          setIsUrlModal(false);
          setLinkToWebsite("");
          setStatusNote("");
        }
        fetchFilterData();
        fetchTasksWithOpenStatus({
          page: selectedPayload?.page,
          pageSize: selectedPayload?.pageSize,
        });
        toast.success("Task status updated");
      }
      //setIsLoading(false);
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();
  const handleSelectedBatch = async (batch) => {
    const TaskId = batch["id"].split(/[^0-9]/)[0];
    navigate(`/reports/review-task/${TaskId}`, {
      state: {
        domain_id: batch.domain_id,
        backlink_id: batch.backlink_id,
        batch_id: batch.batch_id,
        task_status: batch.task_status,
        ready_status_id: batch.ready_status_id,
        userId: user?.user?.id,
        filter: "",
        taskIds: taskIds,
        selectedPayload: selectedPayload,
      },
    });
  };

  useEffect(() => {
    let newFilters;

    if (
      selectedPayload?.user_id !== "" ||
      selectedPayload?.task_status !== "" ||
      selectedPayload?.open_backlinkid !== "" ||
      selectedPayload?.updated_at !== "" ||
      Object.keys(selectedPayload.backlink_data).length !== 0
    ) {
      newFilters = {
        groupOp: "AND",
        rules: [],
      };
      if (selectedPayload?.task_status) {
        newFilters.rules.push({
          field: "bdb.task_status",
          op: "eq",
          data: selectedPayload?.task_status,
        });
      }
      if (selectedPayload?.user_id) {
        newFilters.rules.push({
          field: "u.id",
          op: "eq",
          data: selectedPayload?.user_id,
        });
      }
      if (selectedPayload?.backlink_data?.backlink_id) {
        newFilters.rules.push({
          field: "bl.id",
          op: "eq",
          data: selectedPayload?.backlink_data?._id,
        });
      }
      if (selectedPayload?.updated_at) {
        newFilters.rules.push({
          field: "bdb.updated_at",
          op: "eq",
          data: selectedPayload?.updated_at,
        });
      }
      fetchTasksWithOpenStatus({});
    }
    setFilters(newFilters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPayload?.open_backlinkid]);

  const fetchFilterData = () => {
    let newFilters;
    if (
      selectedPayload?.user_id !== "" ||
      selectedPayload?.task_status !== "" ||
      selectedPayload?.open_backlinkid !== "" ||
      selectedPayload?.updated_at !== "" ||
      Object.keys(selectedPayload.backlink_data).length !== 0
    ) {
      newFilters = {
        groupOp: "AND",
        rules: [],
      };
      if (selectedPayload?.task_status) {
        newFilters.rules.push({
          field: "bdb.task_status",
          op: "eq",
          data: selectedPayload?.task_status,
        });
      }
      if (selectedPayload?.user_id) {
        newFilters.rules.push({
          field: "u.id",
          op: "eq",
          data: selectedPayload?.user_id,
        });
      }
      if (selectedPayload?.backlink_data?.backlink_id) {
        newFilters.rules.push({
          field: "bl.id",
          op: "eq",
          data: selectedPayload?.backlink_data?._id,
        });
      }
      if (selectedPayload?.updated_at) {
        newFilters.rules.push({
          field: "bdb.updated_at",
          op: "eq",
          data: selectedPayload?.updated_at,
        });
      }
      fetchTasksList({
        filter: newFilters,
      });
    } else {
      fetchTasksList({});
    }
    setFilters(newFilters);
  };
  useEffect(() => {
    fetchFilterData();
    getBacklinkStatusList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTasksList = async ({ filter }) => {
    setSelectedData([]);
    setIsLoading(true);
    setTasksList([]);
    const url = `/api/report/review_tasks`;
    try {
      const response = await api.post(url, { filter });
      if (response.status !== 200) {
        toast.error(response?.data?.message);
        setIsLoading(false);
        return;
      }
      setTasksList(response.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const fetchTasksWithOpenStatus = async (paginationData) => {
    setSelectedData([]);
    setIsLoading(isDefault);
    const newDomainId = selectedPayload?.prevPageId
      ? selectedPayload?.prevPageId
      : selectedPayload?.open_backlinkid;
    setTasksList((prev) =>
      prev.map((item) =>
        item["bl.id"] === newDomainId ? { ...item, childRows: [] } : item
      )
    );

    if (newDomainId) {
      const isExpanded =
        selectedPayload?.open_backlinkid || selectedPayload?.prevPageId
          ? true
          : expandedRows[newDomainId];
      /* setExpandedRows({}); */
      await setExpandedRows((prev) => ({
        ...prev,
        [newDomainId]: isExpanded,
      }));
      if (isExpanded) {
        const childData = await fetchChildData(
          newDomainId,
          "",
          selectedPayload,
          paginationData
        );
        const taskIds = childData?.map((item) => item.id);
        setTaskIds(taskIds);
        let uniqueChildData = childData.map((child, index) => ({
          ...child,
          id: `${child.id}-parent-${index}`,
          _id: child.id,
        }));
        setDefault(true);
        setTasksList((prev) =>
          prev.map((item) =>
            item["bl.id"] === newDomainId
              ? { ...item, childRows: uniqueChildData }
              : item
          )
        );
      }
    }
    setIsLoading(false);
  };

  const handleExpandClick = async (id, status, childClick) => {
    setIsLoading(true);
    setExpandedRows({});
    setTasksList((prev) =>
      prev.map((item) => ({
        ...item,
        childRows: [],
      }))
    );
    const shouldExpand = childClick || !expandedRows[id];

    if (shouldExpand) {
      setSelectedPayload((prevState) => ({
        ...prevState,
        prevPageId: id ? id : "",
        pageSize: paginationModel?.pageSize,
        page: paginationModel?.page,
      }));
      let childData = await fetchChildData(id, status, selectedPayload);
      const taskIds = childData.map((item) => item.id);
      setTaskIds(taskIds);
      let uniqueChildData = childData.map((child, index) => ({
        ...child,
        id: `${child.id}-parent-${index}`,
        _id: child.id,
      }));
      setTasksList((prev) =>
        prev.map((item) =>
          item["bl.id"] === id ? { ...item, childRows: uniqueChildData } : item
        )
      );

      setExpandedRows((prev) => ({ ...prev, [id]: true }));
    }

    setIsLoading(false);
  };

  const fetchChildData = useCallback(
    async (parentId, status = null, filter, paginationData) => {
      setIsLoading(true);
      const page = paginationData?.page ? paginationData?.page : filter?.page;
      const pageSize = paginationData?.pageSize
        ? paginationData?.pageSize
        : filter?.pageSize;
      const filters = {
        groupOp: "AND",
        rules: [],
      };
      if (filter?.task_status || filter?.user_id) {
        if (filter?.task_status) {
          filters.rules.push({
            field: "bdb.task_status",
            op: "eq",
            data: filter.task_status,
          });
        }
      } else {
        filters.rules.push({
          field: "bdb.task_status",
          op: "isAnyOf",
          data: ["inReview", "hold"],
        });
      }
      if (filter?.updated_at) {
        filters.rules.push({
          field: "bdb.updated_at",
          op: "eq",
          data: filter?.updated_at,
        });
      }
      const response = await api.post(
        `/api/report/task_info/${parentId}?page=${page}&limit=${pageSize}`,
        {
          filters,
        }
      );
      setTotalChildTasks(response.data);
      setIsLoading(false);
      return response.data?.tasks;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleTopPageChange = (page) => {
    setSelectedPayload((prevState) => ({
      ...prevState,
      page: page + 1,
    }));
  };

  const handleTopPageSizeChange = (newPageSize) => {
    setSelectedPayload((prevState) => ({
      ...prevState,
      page: 1,
      pageSize: newPageSize,
    }));
  };

  useEffect(() => {
    fetchTasksWithOpenStatus({
      page: selectedPayload?.page,
      pageSize: selectedPayload?.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPayload?.page, selectedPayload?.pageSize]);

  return (
    <div className="px-6 pt-4 relative ">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Review Task", link: "" },
        ]}
      />
      <ToastContainer />
      {isLoading && <Loading />}
      <TaskListHeader
        isLoading={isLoading}
        LinkBuilders={LinkBuilders}
        setSelectedPayload={setSelectedPayload}
        selectedPayload={selectedPayload}
        data={tasks}
        targetId={selectedPayload?.prevPageId || ""}
        fetchTasksList={fetchTasksList}
        setTasksList={setTasksList}
        is_browser_active={is_browser_active}
        is_browser_domain={is_browser_domain}
        setExpandedRows={setExpandedRows}
        setFilters={setFilters}
        user={user}
        setDefault={setDefault}
        selectedData={selectedData}
        fetchFilterData={fetchFilterData}
        paginationModel={paginationModel}
        queryParams={queryParams}
      />
      <div className="my-2 border rounded ">
        {tasks.length ? (
          <TaskListCard
            expandedRows={expandedRows}
            handleExpandClick={handleExpandClick}
            handleSelectedBatch={handleSelectedBatch}
            is_browser_active={is_browser_active}
            is_browser_domain={is_browser_domain}
            timeConvert={timeConvert}
            toggleTaskStatusDropdown={toggleTaskStatusDropdown}
            openDropdownIndex={openDropdownIndex}
            updateTask={updateTask}
            setIsUrlModal={setIsUrlModal}
            setTaskStatus={setTaskStatus}
            setErrors={setErrors}
            selectedPayload={selectedPayload}
            fetchFilterData={fetchFilterData}
            backlinksStatusList={backlinksStatusList}
            totalRecords={tasks}
            setSelectedData={setSelectedData}
            selectedData={selectedData}
            setTaskIds={setTaskIds}
            setTasksList={setTasksList}
            setExpandedRows={setExpandedRows}
            totalChildTasks={totalChildTasks}
            handleTopPageChange={handleTopPageChange}
            handleTopPageSizeChange={handleTopPageSizeChange}
          />
        ) : (
          <div className="text-center text-gray-500 py-44">
            No records found
          </div>
        )}
      </div>
      {isUrlModal ? (
        <URLModal
          link_to_website={link_to_website}
          setLinkToWebsite={setLinkToWebsite}
          taskObj={isTaskStatus}
          onClose={() => {
            setTaskStatus({});
            setIsUrlModal(false);
            setStatusNote("");
            setLinkToWebsite("");
          }}
          updateStatus={updateTask}
          setStatusNote={setStatusNote}
          isStatusNote={isStatusNote}
          errors={errors}
        />
      ) : null}
    </div>
  );
}
