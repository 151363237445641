import axios from "axios";
import React, { useState } from "react";
import { Container, FullContainer, InputField } from "../../components";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import { useDispatch } from "react-redux";
import {
  setInstructionModal,
  setIsBrowserActive,
  setIsBrowserDomain,
  setLoggedInUser,
} from "../../store/slices/authSlice";

export default function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleStopBrowserModal = async (event) => {
    try {
      const launch = await api.post("/api/multilogin/stop_browser_static_ip");
      if (launch.status === 200) {
        const urlLaunch = await axios.get(launch.data.stop_url, {
          headers: { Authorization: `Bearer ${launch.data.token}` },
        });
        if (urlLaunch.data?.status?.http_code === 200) {
          const update = await api.post(
            "/api/multilogin/update_browser_status_static_ip",
            {
              is_browser_active: 0,
            }
          );
          if (update.status === 200) {
            dispatch(setIsBrowserActive(null));
            dispatch(setIsBrowserDomain(null));
          }
        }
      }
    } catch (error) {
      if (
        error?.response?.data?.status?.message === "profile already stopped"
      ) {
        dispatch(setIsBrowserActive(null));
        dispatch(setIsBrowserDomain(null));
        return await api.post("/api/multilogin/update_browser_status_static_ip", {
          is_browser_active: 0,
        });
      }
      console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
      const update = await api.post(
        "/api/multilogin/update_browser_status_static_ip",
        {
          is_browser_active: 0,
        }
      );
      if (update.status === 200) {
        dispatch(setIsBrowserActive(null));
        dispatch(setIsBrowserDomain(null));
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    if (!username.trim()) {
      setErrors({ username: "Please enter your username." });
      return;
    }

    if (!password.trim()) {
      setErrors({ password: "Please enter your password." });
      return;
    }
    const data = {
      username: username,
      password: password,
    };
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/auth`,
        data
      );
      dispatch(setLoggedInUser(response?.data));
      if (
        response?.data?.user?.show_popup &&
        (response?.data?.user?.role_id === 3 ||
          response?.data?.user?.role_id === 8)
      ) {
        dispatch(setInstructionModal(true));
      }
      localStorage.setItem("user", JSON.stringify(response?.data));
      localStorage.setItem("accessToken", response?.data?.accessToken);
      localStorage.setItem("refreshToken", response?.data?.refreshToken);
      dispatch(setIsBrowserActive(null));
      dispatch(setIsBrowserDomain(null));
      if (response?.data?.is_browser_active) {
        handleStopBrowserModal();
      }
      // window.location.reload();
      setUsername("");
      setPassword("");
    } catch (err) {
      toast.error("Username or password is incorrect.");
    }
    setIsLoading(false);
  };

  return (
    <div id="LoginForm">
    <FullContainer className="bg-login">
      <ToastContainer />
      <FullContainer className="bg-black/30 py-16 min-h-screen">
        <Container>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10 text-white mt-10 md:mt-0">
            <div className="flex flex-col justify-center">
              <h1 className="text-4xl md:text-6xl leading-none font-bold capilise uppercase font-barlow">
                Robo anywhere manage{" "}
                <span className="text-secondary">backlinks</span> & Link
                builders
              </h1>
              <p className="mt-3 md:mt-7 md:text-lg">
                A backlink is an incoming link to a webpage from another
                website. If a website links to your webiste - this is known as a
                backlink.
              </p>
            </div>
            <div className="flex flex-col items-end justify-center">
              <div className="w-full md:w-8/12 h-fit py-9 px-8 bg-black/30 backdrop-blur-md rounded-xl">
                <h1 className="text-2xl font-bold text-white font-pop">
                  Hi, Welcome Back!
                </h1>
                <p className="text-white font-pop">
                  Robo anywhere services, Join with us!
                </p>
                <form
                  onSubmit={handleSubmit}
                  className="w-full flex flex-col gap-5 mt-8"
                >
                  <InputField
                    name="username"
                    label="username"
                    type="username"
                    placeholder="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    error={errors.username}
                    labelClass="text-white"
                  />
                  <div className="w-full">
                    <InputField
                      name="password"
                      label="Password"
                      type="password"
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      error={errors.password}
                      labelClass="text-white"
                    />
                    <p className="text-sm mt-2 text-secondary text-end underline hover:text-yellow-600">
                      Forgot Password?
                    </p>
                  </div>
                  {isLoading ? (
                    <div className="bg-blue/10 btnPrimary py-2 mt-2 text-base w-full">
                      <img src="/img/loading.gif" className="w-8" alt="" />
                    </div>
                  ) : (
                    <button
                      className="btnPrimary py-2 mt-2 text-base w-full"
                      type="submit"
                    >
                      Login Now
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </Container>
      </FullContainer>
    </FullContainer>
    </div>
  );
}
