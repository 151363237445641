import React, { useState, useRef, useEffect } from "react";
import NewBacklinksTable from "../NewBatches/BacklinksTable";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import Domains from "../NewBatches/Domain";
import AddVerifyBacklink from "./AddVerifyBacklink";
import { Modal } from "../../components";
import api from "../../services/api";
import { ToastContainer } from "react-toastify";
import Loading from "../../components/Loader/Loading";
const DomainsReport = () => {
  const [isPayload, setPayload] = useState({
    domains: [],
    backlinks: [],
  });
  const [isVerifyBacklink, setVerifyBacklink] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("");
  const cancelButtonRef = useRef(null);
  const [isExistingData, setExistingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBacklink, setSelectedBacklink] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const handleVerifyModal = () => {
    setVerifyBacklink(!isVerifyBacklink);
  };
  const handleDomainData = (data) => {
    setPayload({ ...isPayload, domains: data });
  };
  const handleBacklinkData = (data) => {
    setPayload({ ...isPayload, backlinks: data });
  };
  const cancelButtonModal = () => {
    setVerifyBacklink(false);
    setPayload({ ...isPayload, domains: [], backlinks: [] });
    setSelectedBacklink([]);
    setSelectedData([]);
  };
  const getDomainExistTask = async () => {
    setIsLoading(true);
    const payload = {
      domains: isPayload?.domains?.map((item) => item["d.domain"]) || [],
      backlink_ids: isPayload?.backlinks?.map((item) => item["b.id"]) || [],
      ...(user && { user_id: user }),
    };

    try {
      const res = await api.post("/api/domains/existing_tasks", payload);
      if (res.status === 200 || res.status === 201) {
        setExistingData(res?.data);
        setVerifyBacklink(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  const get_user = async () => {
    const filters = {
      groupOp: "AND",
      rules: [
        { field: "is_active", op: "eq", data: 1 },
        { field: "role_id", op: "isAnyOf", data: [3, 8] },
      ],
    };
    try {
      const res = await api.post("/api/users/users_report", {
        filters,
        page: 1,
        perPage: 1000,
      });
      if (res.status === 200) {
        setUsers(res.data?.records);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    get_user();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="px-6 pt-4">
        <CustomBreadcrumbs
          crumbs={[
            { label: "Home", link: "/" },
            { label: "Batches", link: "" },
          ]}
        />
        <ToastContainer />
        {isLoading && <Loading />}
        <div className="bg-white my-2 border rounded">
          <div className="flex items-center space-x-2 py-2 ml-5">
            <select
              onChange={(e) => setUser(e.target.value)}
              value={user}
              name="user_id"
              className="inputField w-[280px] h-[40px] text-black p-2 dark:bg-transparent dark:border-white/30 dark:text-white cursor-pointer rounded-md"
            >
              <option value="" className="dark:bg-blue">
                Select User
              </option>
              {users.map((item, index) => (
                <option key={index} value={item?.id} className="dark:bg-blue">
                  {item?.name.replace(/\b\w/g, (c) => c.toUpperCase())}{" "}
                  {item?.role ? `( ${item?.role} )` : ""}{" "}
                  {item?.pending_tasks ? item?.pending_tasks : ""}
                </option>
              ))}
            </select>
            <button
              type="button"
              className="text-sm hover:bg-blue-700 py-2 px-4 rounded bg-secondary text-white font-normal"
              onClick={() => getDomainExistTask()}
              disabled={
                !(
                  isPayload?.domains?.length > 0 &&
                  isPayload?.backlinks?.length > 0
                )
              }
            >
              Verify Backlinks
            </button>
          </div>
          <div className="flex space-x-0">
            <div className="w-1/2">
              <Domains handleDomainData={handleDomainData} selectedData={selectedData} setSelectedData={setSelectedData}/>
            </div>
            <div className="w-1/2 p-0">
              <NewBacklinksTable
                handleBacklinkData={handleBacklinkData}
                selectedBacklink={selectedBacklink}
                setSelectedBacklink={setSelectedBacklink}
                isPayload={isPayload}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={!isVerifyBacklink}
        handleModal={handleVerifyModal}
        cancelButtonRef={cancelButtonRef}
        className={`max-w-6xl`}
        title={`Preview New Batch`}
      >
        <AddVerifyBacklink
          selectedData={isPayload}
          isExistingData={isExistingData}
          setExistingData={setExistingData}
          cancelButtonModal={cancelButtonModal}
          user={user}
        />
      </Modal>
    </>
  );
};

export default DomainsReport;
