import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataGrid from "../BacklinksTable/DataGrid";
import ToolBar from "../BacklinksTable/DataGrid/ToolBar";
import { columns } from "../BacklinksTable/columns";
import dayjs from "dayjs";
import api from "../../../services/api";
import ImportModal from "../BacklinksTable/ImportModal";
import SourceDomainModal from "../BacklinksTable/SourceDomainModal";
import Loading from "../../../components/Loader/Loading";
import Loader from "../../../components/Loader/Loading";
import { useSelector } from "react-redux";
export default function NewBacklinksTable({
  handleBacklinkData,
  selectedBacklink,
  setSelectedBacklink,
  isPayload,
}) {
  const { user } = useSelector((state) => state.auth);
  const [backlinksData, setBacklinksData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [keyToDelete, setKeyToDelete] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const [toggleStates, setToggleStates] = useState({});
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [nicheProductList, setNicheProductList] = useState([]);

  // Modal
  const [domains, setDomains] = useState([]);
  const [isSourceDomain, setIsSourceDomain] = useState(true);
  const handleModal = (data) => {
    setIsSourceDomain(!isSourceDomain);
    setDomains(data);
  };
  const [backlinksTypeList, setBacklinksTypeList] = useState([]);
  const [backlinksStatusList, setBacklinksStatusList] = useState([]);
  const domainId = isPayload?.domains?.length
    ? isPayload?.domains[0]["d.id"]
    : "";
  const followStatus = [
    { value: "Nofollow", label: "Nofollow" },
    { value: "Dofollow", label: "Dofollow" },
    { value: "Pending", label: "Pending" },
  ];
  const getNicheProductList = () => {
    setIsLoading(true);
    api
      .get(`/api/backlinks/niche_products`)
      .then((res) => setNicheProductList(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  const checkboxColumn = [
    {
      field: "checkbox",
      column: "",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={
            selectedBacklink?.length === backlinksData?.backlinksData?.length
          }
        />
      ),
      filterable: false,
      sortable: false,
      width: 8,
      renderCell: (params) => (
        <div className="flex justify-center w-full items-center">
          {isSelected(params.row.records) && isLoading ? (
            <Loader />
          ) : (
            <input
              type="checkbox"
              checked={isSelected(params.row.records)}
              onChange={(e) =>
                onSingleSelect({
                  checked: e.target.checked,
                  data: params.row.records,
                })
              }
              className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
            />
          )}
        </div>
      ),
      disableColumnMenu: true,
      hideable: false,
    },
  ];

  useEffect(() => {
    if (backlinksData && backlinksData.backlinksData) {
      const initialStates = {};
      backlinksData.backlinksData.forEach((item) => {
        let bid = item["b.id"];
        initialStates[bid] = item["b.good_to_assign"] === 1;
      });
      setToggleStates(initialStates);
    }
  }, [backlinksData]);

  const handleToggle = (params) => {
    setToggleStates((prev) => {
      const currentState =
        prev[params?.id] !== undefined
          ? prev[params?.id]
          : backlinksData?.backlinksData?.find((item) => item.id === params?.id)
              ?.b.good_to_assign === 1;

      const newState = !currentState;
      goodToAssignUpdate(
        params?.id,
        newState,
        params?.row["b.backlink_domain"]
      );
      return {
        ...prev,
        [params?.id]: newState,
      };
    });
  };

  const goodToAssignUpdate = async (bid, newState, backlink_domain) => {
    setIsLoading(true);
    try {
      const res = await api.put(`/api/backlinks/updateBacklink`, {
        goodToAssign: newState,
        id: bid,
        backlink_domain: backlink_domain,
      });
      if (res.status === 200 || res.status === 201) {
        toast.success("Good To Assign Status Changed");
        /* fetchBacklinksData({
          size: paginationModel.pageSize,
          page: paginationModel.page,
          filter: queryOptions,
        }); */
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  const columnDefs = columns({
    handleModal: handleModal,
    backlinksTypeList: backlinksTypeList,
    backlinksStatusList: backlinksStatusList,
    followStatus: followStatus,
    checkboxColumn: checkboxColumn,
    handleToggle: handleToggle,
    toggleStates: toggleStates,
    nicheProductList: nicheProductList,
  });
  const [isImport, setIsImport] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });

  const getBacklinkStatusList = () => {
    setIsLoading(true);
    api
      .get(`/api/backlinks/backlink_status_list`)
      .then((res) => setBacklinksStatusList(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  //   Get Type List
  const getBacklinkTypeList = () => {
    setIsLoading(true);
    api
      .get(`/api/backlinks/backlink_type_list`)
      .then((res) => setBacklinksTypeList(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getBacklinkTypeList();
    getBacklinkStatusList();
    getNicheProductList();
  }, []);
  const onSelectAll = (checked) => {
    if (isLoading) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (checked) {
      const updatedArray = backlinksData?.backlinksData?.map((item) => item);
      setSelectedBacklink(updatedArray);
    } else {
      setSelectedBacklink([]);
    }
  };
  const isSelected = (item) => {
    if (selectedBacklink?.length > 0) {
      if (
        selectedBacklink?.filter(
          ({ "b.backlink_domain": backlinkDomain }) =>
            backlinkDomain && backlinkDomain === item["b.backlink_domain"]
        ).length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  const onSingleSelect = ({ checked, data }) => {
    if (isLoading) {
      return toast.error("Please wait until the previous process is complete.");
    }
    try {
      if (checked) {
        setSelectedBacklink((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedBacklink((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            ({ "b.backlink_domain": backlinkDomain }) =>
              backlinkDomain && backlinkDomain !== data["b.backlink_domain"]
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    handleBacklinkData(selectedBacklink);
  }, [selectedBacklink]);

  const fetchBacklinksData = async (payload) => {
    setSelectedBacklink([]);
    payload.user_id = user?.user?.id;
    payload.is_assigned = true;
    if (domainId) {
      payload.domain_id = domainId;
    }
    setIsLoading(true);
    setBacklinksData([]);
    api
      .post(`${process.env.REACT_APP_PUBLIC_API}/api/backlinks/`, payload)
      .then((response) => {
        setBacklinksData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const fetchStates = async () => {
    setIsLoading(true);
    /*   api
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/location/stateList`)
      .then((response) => {
        setStates(response?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      }); */
  };
  useEffect(() => {
    fetchBacklinksData({
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      filter: selectedFilter?.map((filter) => [
        filter.filter_key,
        filter?.filter_type[filter.selectedType],
        filter.selectedValue,
      ]),
    });
    // fetchBacklinksFilter();
    fetchStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainId]);
  const refreshBacklinksData = () => {
    fetchBacklinksData({
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      filter: selectedFilter?.map((filter) => [
        filter.filter_key,
        filter?.filter_type[filter.selectedType],
        filter.selectedValue,
      ]),
    });
  };
  /*  const stateOptions = Object.entries(states).map(([value, label]) => ({
    value,
    label,
  })); */
  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.delete(
        `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/${keyToDelete}`
      );
      if (response.status === 200) {
        console.log(
          `Row with ID ${keyToDelete} has been deleted successfully.`
        );
        // Update local state by filtering out the deleted row
        setBacklinksData((prevData) => {
          // Get the updated state data passed to the callback
          const updatedData = prevData.backlinksData?.filter(
            (backlink) => backlink.id !== keyToDelete
          );
          // Return the updated state data to update the local state
          return { ...prevData, backlinksData: updatedData };
        });

        toast.success("Backlink Deleted", { autoClose: 3000 });
      } else {
        toast.error("Backlink couldn't be Deleted", { autoClose: 3000 });
        console.log(`Failed to delete row with ID ${keyToDelete}.`);
      }
    } catch (error) {
      console.error(`Error deleting row with ID ${keyToDelete}: ${error}`);
      toast.error(error.response.data.message);
    }
    setKeyToDelete("");
    handleModal();
  };

  /*   const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    console.log("👊 ~ handlePageChange ~ params:", params);
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    fetchBacklinksData({
      page: +params.page + 1,
      perPage: params.pageSize,
      filter: selectedFilter?.map((filter) => [
        filter.filter_key,
        filter.filter_type[filter.selectedType],
        filter.selectedValue,
      ]),
    });
  }; */
  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  const processRowUpdate = async (newRow, oldRow) => {
    // updated field names
    const updatedRow = newRow;
    const createdRows = []; // Allow adding only one row at a time.
    const updatedRows = [];

    if (updatedRow?.isNew || updatedRow.records?.isNew) {
      createdRows.push({
        ...updatedRow,
        backlink_type_id: updatedRow?.backlink_type_id,
        ready_status_id: updatedRow?.ready_status_id,
        isNew: undefined,
        customId: undefined,
      });
    } else {
      updatedRows.push({
        ...updatedRow,
        backlink_type_id: updatedRow?.backlink_type_id,
        ready_status_id: updatedRow?.ready_status_id,
      });
    }

    try {
      // update
      if (updatedRows.length) {
        api
          .put(
            `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/updateBacklink`,
            {
              id: updatedRows[0]["b.id"]?.toString(),
              backlink_domain: updatedRows[0]["b.backlink_domain"],
              signup_url: updatedRows[0]["b.signup_url"] || "",
              backlink_location_type:
                updatedRows[0]["b.backlink_location_type"],
              backlink_type_id: updatedRows[0]["b.backlink_type_id"],
              domain_rating: updatedRows[0]["b.domain_rating"],
              ready_status_id: updatedRows[0]["b.ready_status_id"],
              follow_status: updatedRows[0]["b.follow_status"],
              backlink_rank: updatedRows[0]["b.backlink_rank"],
              traffic: updatedRows[0]["b.traffic"],
            }
          )
          .then(() => {
            fetchBacklinksData({
              page: paginationModel?.page,
              perPage: paginationModel?.pageSize,
              filter: [],
            }); // Fetch updated data from the server and set it in local state
            toast.success("Backlink Updated", { autoClose: 3000 });
          })
          .catch((error) => {
            console.log(error);
            toast.error(error.response.data.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
      // create
      if (createdRows.length === 1) {
        api
          .post(
            `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/addBacklink`,
            {
              backlink_domain: createdRows[0]["b.backlink_domain"],
              signup_url: createdRows[0]["b.signup_url"] || "",
              backlink_location_type:
                createdRows[0]["b.backlink_location_type"],
              backlink_type_id: createdRows[0]["b.backlink_type_id"],
              domain_rating: createdRows[0]["b.domain_rating"],
              ready_status_id: createdRows[0]["b.ready_status_id"],
              follow_status: createdRows[0]["b.follow_status"],
              backlink_rank: createdRows[0]["b.backlink_rank"],
              traffic: createdRows[0]["b.traffic"],
            }
          )
          .then(() => {
            fetchBacklinksData(); // Fetch updated data from the server and set it in local state
            toast.success("New Backlink Created!", { autoClose: 3000 });
          })
          .catch((error) => {
            console.log(error);
            toast.error(error.response.data.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
      return updatedRow;
    } catch (e) {
      console.log(e);
    }
  };
  /*   const CustomPagination = (props) => {
    const { paginationProps, api } = props;
    const handleLastPageClick = () => {
      api.setPage(api.state.pagination.pageCount);
    };
    return (
      <GridPagination
        {...paginationProps}
        showLastButton
        showFirstButton
        renderLastButton={(params) => (
          <button onClick={handleLastPageClick} disabled={params.disabled}>
            Last Page
          </button>
        )}
      />
    );
  }; */
  const rows = (backlinksData?.backlinksData || [])?.map((record, index) => {
    const counter = offset + index + 1;
    const records = record;
    const {
      "b.id": id,
      "b.backlink_domain": backlink_domain,
      "b.signup_url": signup_url,
      "b.type": type,
      "b.blacklisted": blacklisted,
      "b.domain_rating": domain_rating,
      "b.robot_group": robot_group,
      "b.ready_status": ready_status,
      "b.follow_status": follow_status,
      "b.traffic": traffic,
      "b.backlink_type_id": backlink_type_id,
      "b.ready_status_id": ready_status_id,
      "b.is_verified": is_verified,
      "b.backlink_paid_status": backlink_paid_status,
      intersect_count,
      city,
      state,
    } = record;
    const source_domains_list_count =
      record["bi.source_domains_list"]?.split(",")?.length;
    const created_at = dayjs(record["b.created_at"]).format(
      "ddd, MMM D, YYYY h:mm A"
    );
    const updated_at = dayjs(record["b.updated_at"]).format(
      "ddd, MMM D, YYYY h:mm A"
    );
    return {
      counter,
      records,
      "b.id": id,
      "b.backlink_domain": backlink_domain,
      "b.signup_url": signup_url,
      "b.type": type || "",
      "b.blacklisted": blacklisted,
      "b.domain_rating": domain_rating,
      "b.robot_group": robot_group,
      "b.ready_status": ready_status || "",
      "b.follow_status": follow_status || "",
      "b.traffic": traffic,
      "b.backlink_type_id": backlink_type_id,
      "b.ready_status_id": ready_status_id,
      "b.is_verified": is_verified,
      "b.backlink_paid_status": backlink_paid_status,
      intersect_count,
      city,
      state,
      "b.created_at": created_at,
      "b.updated_at": updated_at,
      source_domains_list_count,
    };
  });

  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    fetchBacklinksData({
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      filter: queryOptions,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel?.items?.length === 0) {
      fetchBacklinksData({
        filter: [],
        page: 1,
        perPage: paginationModel.pageSize,
      });
    }
    let ruless = [];
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "isEmpty"
              ? "eq"
              : rule.operator,
          data:
            rule.field === "b.niche_product_ids" && rule.value
              ? [rule.value]
              : rule.value
              ? rule.value
              : null,
        },
      ];
    });
    // ?.toLowerCase()?.includes(searchInput?.toLowerCase())
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 pt-0">
      <div className="font-semibold text-2xl">Backlinks</div>
      {isLoading && <Loading />}
      {!isImport && (
        <ImportModal
          isImport={isImport}
          setOpen={setIsImport}
          //stateOptions={stateOptions}
          fetchBacklinksData={fetchBacklinksData}
        />
      )}
      {!isSourceDomain && (
        <SourceDomainModal
          domains={domains}
          setOpen={setIsSourceDomain}
          open={isSourceDomain}
        />
      )}
      <div className="bg-white my-3 border rounded">
        <DataGrid
          initialState={{ pinnedColumns: { right: ["batch_action"] } }}
          rows={rows}
          columns={columnDefs}
          pinActions={true}
          components={{
            toolbar: ToolBar,
          }}
          componentsProps={{
            toolbar: {
              rowModesModel,
              setBacklinksData,
              setRowModesModel,
              fetchBacklinksData,
              paginationModel,
              setSelectedFilter,
              setPaginationModel,
              rows,
              selectedFilter,
              setIsLoading,
              handleNewFilter,
              backlinksData,
            },
          }}
          editMode="row"
          getRowId={(row) => row["b.id"]}
          rowCount={backlinksData?.totalCount || 0}
          setRowModesModel={setRowModesModel}
          rowModesModel={rowModesModel}
          editable={false}
          pagination="No"
          showCount="No"
          height="100vh"
          density="standard"
          processRowUpdate={processRowUpdate}
          setBacklinksData={setBacklinksData}
          handleDeleteSubmit={handleDeleteSubmit}
          onFilterModelChange={onFilterChange}
          fetchBacklinksData={refreshBacklinksData}
          columnVisibilityModel={{
            "b.follow_status": false,
            "b.traffic": false,
            "b.domain_rating": false,
            "b.created_at": false,
            "b.updated_at": false,
            "b.niche_product_ids": false,
          }}
        />
      </div>
    </div>
  );
}
