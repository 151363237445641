import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import { InputField, SelectBox } from "../../components";
import Loading from "../../components/Loader/Loading";
export default function AddUpdateForm({
  handleModal,
  fetchData,
  isLoading,
  setIsLoading,
  modalType,
  subcategory,
  isCategorys,
}) {
  const [niche_category_id, setCategory] = useState(
    localStorage.getItem("niche_category") ?? ""
  );
  const [niche_sub_category, setSubCategory] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (modalType === "edit") {
      setCategory(subcategory.niche_category_id);
      setSubCategory(subcategory.niche_sub_category);
    } else if (modalType === "new") {
      setSubCategory("");
    }
  }, [modalType, subcategory]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Form validation
    const errors = {};

    if (!niche_sub_category.trim()) {
      errors.niche_sub_category = "Please enter the sub category.";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsLoading(false);
      return;
    } else {
      setErrors({});
    }

    const payloadData = {
      niche_category_id,
      niche_sub_category,
    };
    if (modalType === "new") {
      api
        .post(
          `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/niche_sub_category`,
          payloadData
        )
        .then(() => {
          handleModal();
          fetchData();
          toast.success("New Subcategory Created!", { autoClose: 3000 });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (modalType === "edit" && subcategory.id) {
      api
        .put(
          `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/niche_sub_category/${subcategory?.id}`,
          {
            ...payloadData,
          }
        )
        .then(() => {
          handleModal();
          fetchData();
          toast.success("Subcategory updated successfully!", {
            autoClose: 3000,
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const handleSelectedOptionsChange = (event) => {
    setCategory(event.target.value);
  };
  return (
    <div>
      {isLoading && <Loading />}
      <div className="grid md:grid-cols-1 gap-4 w-full">
        <SelectBox
          placeholder={"Select Category"}
          onChange={handleSelectedOptionsChange}
          name="Category"
          valueKey="id"
          labelKey="niche_category"
          options={isCategorys}
          value={niche_category_id}
          selectValue="Select Niche Category"
          height="h-[42px]"
        />
      </div>
      <div className="grid md:grid-cols-1 gap-4 w-full pt-3">
        <InputField
          label="Sub Category Name"
          name="niche_sub_category"
          placeholder="Enter Name"
          value={niche_sub_category}
          onChange={(e) => setSubCategory(e.target.value)}
          error={errors.niche_sub_category}
          required={true}
        />
      </div>
      <div className="flex items-cente justify-end mt-5 space-x-2 text-white">
        <button
          onClick={handleModal}
          type="button"
          className="btnPrimary bg-red-500"
        >
          Cancel
        </button>

        <button
          type="button"
          onClick={handleSubmit}
          className="btnPrimary bg-green-600"
        >
          Save and Update
        </button>
      </div>
    </div>
  );
}
